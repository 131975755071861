import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { translateText, translateImageCaption } from '../config/translation';


const useStyles = makeStyles(() => ({
  headerImageWrapper: {
    maxWidth: 1400,
    margin: '0 auto',
  },
  textWrapper: {
    padding: 32,
    textAlign: 'justify',
  },
  imgCaption: {
    textAlign: 'center',
    fontSize: 14,
    fontWeight: 300,
  },
}))

const Registry = ({ path, lang}) => {
  const classes = useStyles()

  const data = useStaticQuery(graphql`
  query {
      kellioChildren: file(relativePath: { eq: "images/kellio-children.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout pageName='Registry' activeRoute={ path } lang={ lang }>
      <SEO title="Registry" />
      <Box className={ classes.headerImageWrapper }>
        <Img fluid={data.kellioChildren.childImageSharp.fluid} />
        <p className={ classes.imgCaption }>{ translateImageCaption("Gifts, gifts, gifts!", lang) }</p>
      </Box>
      <Container maxWidth="md" className={ classes.textWrapper }>
        <p>{ translateText("We are so excited to celebrate our union with our dearest family and friends, and the gift of your presence is truly the best one! Please do not feel obliged to give us a gift.", lang) }</p>
        <p>{ translateText("We have been longtime renters in Philadelphia and are working hard to be able to purchase a home of our own one day. Should you want to contribute, we will lovingly invest any monetary gifts toward our future home.", lang) }</p>
        <p>{ translateText("If you would prefer to purchase a gift for us, we have created a brief registry on ", lang) }<a href="https://www.zola.com/registry/kellyandhelio">Zola</a>.</p>
        <p>{ translateText("Handmade gifts are always appreciated!", lang) }</p>
      </Container>
    </Layout>
  )
}

export default Registry
